.center-text {
    background-color: black;
    color: white;
    text-align: center;
  }
  
  .message-container {
    background-color: black;
    color: white;
    position: relative;
  }
  
  .message {
    text-align: center;
    background-color: black;
    color: white;
    opacity: 1;
    transition: opacity 2s ease-in-out; /* Slower transition */
  }
  
  .fade-out {
    opacity: 0;
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .flash-animation {
    animation: flash 2s infinite; /* Flash animation with 1 second duration */
  }
